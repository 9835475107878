@import url("http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700");
@media only screen and (min-width: 769px) {
  .card-group {
    gap: 30px;
    margin-bottom: 30px;
  }
}
.card-group .card.card-linkin {
  border: none;
  padding: 26px;
  border-radius: 6px;
  background-color: #1c1f26;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 8px;
  border: solid 1px rgba(168, 179, 207, 0.4);
}
@media only screen and (max-width: 768px) {
  .card-group .card.card-linkin {
    flex: inherit;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 769px) {
  .card-group .card.card-linkin {
    position: relative;
    padding: 26px 26px 47px;
  }
}
.card-group .card.card-linkin .card-body {
  padding: 0;
}
.card-group .card.card-linkin .card-body .card-title a {
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  font-weight: 900;
  text-decoration: none;
}
.card-group .card.card-linkin .card-body p {
  font-size: 16px;
  color: #ffffff;
}
.card-group .card.card-linkin .card-body .card-link {
  font-size: 18px;
  color: #a8b3cf;
  text-decoration: none;
}
@media only screen and (min-width: 769px) {
  .card-group .card.card-linkin .card-body .card-link {
    position: absolute;
    bottom: 26px;
  }
}
.card-group .card.card-linkin .card-body .card-link::after {
  display: inline-block;
  margin-left: 8px;
  vertical-align: inherit;
  font-family: "FontAwesome";
  content: "\f178";
  line-height: 0;
}
.card-group .card.card-linkin.card-default:hover .card-title a, .card-group .card.card-linkin.card-default:hover .card-link, .card-group .card.card-linkin.card-default:focus-within .card-title a, .card-group .card.card-linkin.card-default:focus-within .card-link {
  text-decoration: underline;
}
.card-group .card.card-linkin.card-default:hover .card-title a, .card-group .card.card-linkin.card-default:hover .card-link {
  text-decoration: underline;
}
.card-group .card.card-linkin:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 30px 0;
  border-color: transparent #39b54a transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
}
.card-group .card.card-linkin.card-emc:after {
  border-color: transparent #E0B332 transparent transparent;
}
.card-group .card.card-linkin.card-htsm:after {
  border-color: transparent #e1e9f0 transparent transparent;
}
.card-group .card.card-linkin.card-mmm:after {
  border-color: transparent #00AEEF transparent transparent;
}
.card-group i.fa {
  background: #0e1217;
  color: #a8b3cf;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 50%;
  transition: all 0.5s;
  -webkit-transform: translateZ(0);
  width: 42px;
  height: 42px;
  line-height: 42px;
}

body {
  background-color: #0e1217;
}
body header .heading-identity {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
body .border-bottom {
  border-color: rgba(168, 179, 207, 0.4) !important;
}

h2 {
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  font-weight: 900;
  text-decoration: none;
}

p {
  color: #ffffff;
}

.container {
  max-width: 960px;
}

.clickable-div {
  cursor: pointer;
}