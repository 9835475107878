.card-group {
    @media only screen and (min-width: 769px) {
        gap: 30px;
        margin-bottom: 30px;
    }
    .card.card-linkin {
        border: none;
        padding: 26px;
        border-radius: 6px;
        background-color: $primarycolor;
        position: relative;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 8px;
        border: solid 1px rgba(168, 179, 207, .4);
        @media only screen and (max-width: 768px) {
            flex: inherit;
            margin-bottom: 20px;
        }
        @media only screen and (min-width: 769px) {
            position: relative;
            padding: 26px 26px 47px;
        }
        .card-body {
            padding: 0;
            .card-title a {
                font-size: 20px;
                line-height: 26px;
                color: $white;
                font-weight: 900;
                text-decoration: none;
            }
            p {
                font-size: 16px;
                color: $white;
            }
            .card-link {
                font-size: 18px;
                color: $secondarycolor;
                text-decoration: none;
                @media only screen and (min-width: 769px) {
                    position: absolute;
                    bottom: 26px;
                }
                &::after {
                    display: inline-block;
                    margin-left: 8px;
                    vertical-align: inherit;
                    font-family: "FontAwesome";
                    content: "\f178";
                    line-height: 0;
                }
            }
        }
        &.card-default {
            &:hover, &:focus-within {
                .card-title a, .card-link {
                    text-decoration: underline;
                }
            }
            &:hover {
                .card-title a, .card-link {
                    text-decoration: underline;
                }
            }
        }
        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 30px 30px 0;
            border-color: transparent #39b54a transparent transparent;
            right: 0;
            top: 0;
            position: absolute;
          }
        &.card-emc {
            &:after {
                border-color: transparent #E0B332 transparent transparent;
              }
        }
        &.card-htsm {
            &:after {
                border-color: transparent #e1e9f0 transparent transparent;
              }
        }
        &.card-mmm {
            &:after {
                border-color: transparent #00AEEF transparent transparent;
              }
        }
    }
    i.fa {
        background: $defaultcolor;
        color: $secondarycolor;
        display: inline-block;
        cursor: pointer;
        margin: 0;
        padding: 0;
        text-align: center;
        position: relative;
        z-index: 1;
        overflow: hidden;
        border-radius: 50%;
        transition: all 0.5s;
        -webkit-transform: translateZ(0);
        width: 42px;
        height: 42px;
        line-height: 42px;
    }
}