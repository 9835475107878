@import url('http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700');
$font-family-primary: "Open Sans", sans-serif;

$defaultcolor: #0e1217;
$primarycolor: #1c1f26;
$secondarycolor: #a8b3cf;
$tertiarycolor: #494e5b;
$white: #ffffff;

$arrow-size: 1em;
$arrow-border: 0.2em;